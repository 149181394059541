import MenuBar from "./Component/MenuBar";
import Footer from "./Component/Footer";
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <>

            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>Contact US</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>

                            <li className="active"> Get In Touch</li>
                        </ul>
                    </div>
                </div>

            </section>




            {/* <!--Contact Section--> */}
            <section className="contact-section contact-page">
                {/* <!-- <div className="icon-one paroller" data-paroller-factor="-0.20" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-heart-2"></span></div> -->
            <!-- <div className="icon-two paroller" data-paroller-factor="0.20" data-paroller-factor-lg="0.15" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-heart-2"></span></div> --> */}

                <div className="icon-three paroller" data-paroller-factor="-0.10" data-paroller-factor-lg="-0.15" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-dove"></span></div>

                <div className="auto-container">

                    <div className="sec-title centered">
                        <div className="upper-icon"></div>
                        <h2>Get In Touch!</h2>
                        {/* <!-- <div className="text">Join Us To Save The World Of Humanitarian And Feel Happy To Support</div> --> */}
                    </div>
                    <div className="row clearfix">
                        {/* <!--Form Column---> */}
                        <div className="form-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                            <div className="inner">
                                {/* <!-- <div className="shape-box"><h3>Working Together To Make A Difference</h3></div> --> */}
                                <div className="default-form contact-form">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="inner-button">


                                            <a href="https://ykportal.yugalkunj.org/home/contact" target="_blank" className="theme-btn btn-style-one" style={{ width: '300px', textAlign: 'center', color: '#fff' }}><span className="btn-title"> Please Click Here</span></a>

                                        </div>
                                    </div>

                                    {/* <!-- <form method="post" action="#" id="contact-form">
                                <div className="row clearfix">
                                    <div className="col-md-6 col-sm-12 form-group">
                                        <div className="field-label">Your Name</div>
                                        <input type="text" name="username" placeholder="" required="">
                                    </div>

                                    <div className="col-md-6 col-sm-12 form-group">
                                        <div className="field-label">Email</div>
                                        <input type="email" name="email" placeholder="" required="">
                                    </div>

                                    <div className="col-md-6 col-sm-12 form-group">
                                        <div className="field-label">Phone</div>
                                        <input type="text" name="phone" placeholder="" required="">
                                    </div>

                                    <div className="col-md-6 col-sm-12 form-group">
                                        <div className="field-label">Subject</div>
                                        <input type="text" name="subject" placeholder="" required="">
                                    </div>

                                    <div className="col-md-12 col-sm-12 form-group">
                                        <div className="field-label">Message</div>
                                        <textarea name="message" placeholder="Write your message"></textarea>
                                    </div>

                                    <div className="col-md-12 col-sm-12 form-group">
                                        <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title">Send Message</span></button>
                                    </div>
                                </div>
                            </form> --> */}
                                </div>
                            </div>
                        </div>
                        {/* <!--Image Column---> */}
                        <div className="image-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="icon-1 paroller" data-paroller-factor="-0.30" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.20" data-paroller-type="foreground" data-paroller-direction="vertical"></div>
                                <div className="icon-2 paroller" data-paroller-factor="0.30" data-paroller-factor-lg="0.20" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="vertical"></div>
                                <figure className="image">
                                    <img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/featured-image-3.jpg" alt="" /></figure>
                            </div>
                        </div>

                    </div>

                    <div className="info-container">
                        <div className="row clearfix">
                            {/* <!--Info Block--> */}

                            {/* <!--Info Block--> */}
                            {/* <div className="info-block bg-light-blue col-xl-4 col-lg-6 col-md-6 col-sm-12">
                            <div className="inner">
                                <h3>Shyama Shyam Dham
                                    Milwaukee, Wisconsin
                                    </h3>
                                <ul className="info">
                                    <li><span className="fa fa-map-marker-alt"></span> W173 N9170 St Francis Dr.
                                        Suite 12
                                        Menomonee Falls WI, 53051
                                        
                                    </li>
                                    <li><span className="fa fa-phone-alt"></span> <a href="tel:812-070-3692">Phone  (262) 252-7185</a></li>
                                    <li><span className="fa fa-envelope-open"></span> <a href="mailto:deepti_dhingra@yahoo.com"> Deepti </a> or <a href="mailto:anoop_dhingra@yahoo.com">Anoop Dhingra </a></li> 

                                </ul>
                                <ul className="social-links clearfix">
                                    <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                                    <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                    <li><a href="#"><span className="fab fa-vimeo-v"></span></a></li>
                                    <li><a href="#"><span className="fab fa-instagram"></span></a></li>
                                    <li><a href="#"><span className="fab fa-youtube"></span></a></li>
                                </ul>
                            </div>
                        </div> */}
                            {/* <!--Info Block--> */}
                            <div className="info-block bg-light-blue col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="inner">
                                    <h3>Yugalkunj Toronto Canada</h3>
                                    <ul className="info">
                                        <li><span className="fa fa-map-marker-alt"></span> 375 Aberdeen Ave, Hamilton, ON L8P 2R7, Canada</li>
                                        <li><span className="fa fa-phone-alt"></span> <a href="tel:+1 (289) 389-5311">Phone +1 (289) 389-5311</a></li>
                                        <li><span className="fa fa-envelope-open"></span> <a href="mailto:info@yugalkunjtoronto.org">info@yugalkunjtoronto.org</a></li>
                                        {/* <!-- <li style="visibility: hidden;"><span className="fa fa-envelope-open"></span> <a href="mailto:info@radhamadhavsociety.org">info@radhamadhavsociety.org</a></li>  --> */}

                                    </ul>
                                    <ul className="social-links clearfix">
                                        <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                                        <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                        <li><a href="#"><span className="fab fa-vimeo-v"></span></a></li>
                                        <li><a href="#"><span className="fab fa-instagram"></span></a></li>
                                        <li><a href="#"><span className="fab fa-youtube"></span></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="info-block bg-blue col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="inner">
                                    <h3>Yugal Kunj</h3>
                                    <ul className="info">
                                        <li><span className="fa fa-map-marker-alt"></span> 2769 Duluth Hwy Duluth, GA 30096</li>
                                        <li><span className="fa fa-phone-alt"></span> <a href="tel:+1 (289) 389-5311">Phone +1 678-920-6669</a></li>
                                        <li><span className="fa fa-envelope-open"></span> <a href="mailto:info@yugalkunjtoronto.org">info@yugalkunj.org</a></li>
                                        {/* <li style={visibility: hidden;"><span className="fa fa-envelope-open"></span> <a href="mailto:info@yugalkunj.org">info@yugalkunj.org</a></li> */}

                                    </ul>
                                    <ul className="social-links clearfix">
                                        <li><a href="https://www.facebook.com/yugalkunj/" target="_blank"><span className="fab fa-facebook-f"></span></a></li>
                                        <li><a href="https://twitter.com/search?q=yugalkunj" target="_blank"><span className="fab fa-twitter"></span></a></li>
                                        <li><a href="https://www.yelp.com/biz/yugal-kunj-duluth-2" target="_blank"><span className="fab fa-yelp"></span></a></li>
                                        <li><a href="https://www.instagram.com/yugal_kunj/" target="_blank"><span className="fab fa-instagram"></span></a></li>
                                        <li><a href="https://video.search.yahoo.com/search/video;_ylt=AwrC1DGkf51cEi8A_gzQtDMD;_ylu=X3oDMTB0N2Noc21lBGNvbG8DYmYxBHBvcwMxBHZ0aWQDBHNlYwNwaXZz?p=yugal+kunj+duluth+ga&amp;fr2=piv-web&amp;fr=yfp-t-s" target="_blank"><span className="fab fa-yahoo"></span></a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Map Section--> */}
            <section className="map-section">
                {/* <!--Map Outer--> */}

                <div className="auto-container">

                    <div className="row">

                        <div className="col-md-12">
                            <div class="map">

                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.065578691638!2d-79.89234328539278!3d43.25004738642558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9b6c61cff5eb%3A0xec2d7e761feaf66f!2s375%20Aberdeen%20Ave%2C%20Hamilton%2C%20ON%20L8P%202R7%2C%20Canada!5e0!3m2!1sen!2sin!4v1679310012445!5m2!1sen!2sin"
                                    width="100%"
                                    height="250"
                                    frameborder="0"
                                    style={{ border: 0 }}
                                    allowfullscreen=""
                                    aria-hidden="false"
                                    tabindex="0"
                                >

                                </iframe>
                            </div>


                            {/* <div className="map">

                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6616.706205168105!2d-84.12566100000001!3d33.983463!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a2e90fd1f963%3A0x1ec46d89ce53332c!2s2769%20Duluth%20Hwy%2C%20Duluth%2C%20GA%2030096!5e0!3m2!1sen!2sus!4v1589379025209!5m2!1sen!2sus" 
                                width="100%" 
                                height="250"
                                frameborder="0" 
                                style={{border:0}}
                                allowfullscreen=""
                                aria-hidden="false"
                                    tabindex="0"
                                >

                            </iframe>
                        </div> */}

                        </div>
                    </div>
                </div>

            </section>
            {/* <!--End Map Section--> */}
            <Footer />




        </>

    )


}

export default Contact;