import Footer from "./Component/Footer";
// import Header from "./Component/Header";
import MenuBar from "./Component/MenuBar";
import { NavLink } from "react-router-dom";


const Directions = () => {
    return (
        <>
            <MenuBar />

            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>Directions</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>

                            <li className="active"> Directions</li>
                        </ul>
                    </div>
                </div>

            </section>



            {/* <!--FAQs Section--> */}
            <section className="faq-section">
                <div className="auto-container">

                    <div className="row clearfix">

                        <div className="col-md-12">



                            <div className="panel panel-primary">
                                <div className="panel panel-heading">

                                    <h4>Directions</h4>
                                </div>


                                <div className="panel panel-body">
                                    <div className="inner-heading">



                                        <div className="text">

                                            <ul>

                                                <li> <img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/icons/map-marker.png" /> Yugal Kunj is located at 2769 Duluth Hwy, Duluth, GA 30096</li>

                                                {/* <!-- <li>Yugal Kunj is located at 2769 Duluth Hwy, Duluth, GA 30096</li> --> */}
                                            </ul>
                                        </div>



                                        <div className="inner_heading_title orange-border" style={{ marginTop: '10px' }}>
                                            <h4>Travel to Yugal Kunj from Hartsfield-Jackson International Airport</h4>

                                            <div className="text">

                                                <p>At airport: On arrival at the gate – take the “plane train” towards baggage claim area. Baggage claim is the last stop on the train.</p>

                                                <p className="lead">
                                                    You have 3 options to travel to Yugal Kunj temple
                                                </p>



                                                <ul className="satsang_list">

                                                    <li>Take a taxi cab from airport to Yugal Kunj</li>
                                                    <li>
                                                        Take Uber or Lyft taxi service
                                                    </li>
                                                    <li>
                                                        Take Gold line train from airport to Doraville station and then take Uber taxi – $15 to $20
                                                    </li>

                                                </ul>
                                            </div>

                                        </div>


                                        <div className="inner_heading_title purple-border">
                                            <h4> Using taxi cab from airport</h4>

                                            <div className="text">

                                                <p className="lead">Travel time: approx. one hour (depending on traffic)</p>
                                                <p className="lead">Travel cost: approx. $75</p>

                                                <p><strong>NOTE:</strong> You cannot schedule a ride with Uber or Lyft until you are ready to ride.  So schedule a ride only after you have picked up your luggage at Baggage claim and ready to exit the airport.</p>

                                                <ul className="Number_list">

                                                    <li>Install Uber app or the Lyft app on your iPhone or Android phone.</li>
                                                    <li>If you do not have an account already setup with this service, you will have to enter your credit card information during registration.</li>
                                                    <li>Tha app will tell you exactly which make/model of the car is assigned to your ride, along with the photo, name, and phone number of the driver.</li>
                                                    <li>The driver of the vehicle assigned to you will call you to find out where you are at the airport.  You must provide the number of the door from which you are exiting the airport.  For example it could be S3 if exiting from Gate 3 on the South side of the terminal, or it could be N3 if exiting from the North side of the terminal.</li>
                                                    <li>Singing</li>
                                                    <li>Indian languages such as Hindi</li>
                                                    <li>The vehicle usually arrives within 5 minutes of your request.</li>
                                                    <li>Do not pay anything to the driver on reaching your destination.  Your credit card will be charged automatically.</li>


                                                </ul>
                                            </div>

                                        </div>




                                        <div className="inner_heading_title purple-border">
                                            <h4> Using MARTA train from airport</h4>

                                            <div className="text">

                                                <p className="lead">Travel time: approx. 1hour, 15 minutes</p>
                                                <p className="lead">Travel cost: approx. $4.50 per person + $15 taxi ride</p>

                                                <p>Arriving air travelers should follow the Ground Transportation signs to MARTA. The entrance to MARTA’s Airport Station is located inside the western end of the airport’s main terminal. The faregates are just a few feet from the baggage claim areas. Follow the signs, they are plentiful.</p>

                                                <p><strong>NOTE: </strong>Train has NO restroom/toilet or water facility – please buy water bottle etc from airport.</p>
                                                <div className="text">
                                                    <p style={{ paddingLeft: "40px" }}>You will use the GOLD Line to travel from Airport to Doraville Station (Final Stop)</p>

                                                    <img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/MartaGoldLine.jpg" className="img-responsive" />
                                                </div>


                                                <p className="lead">Train Frequencies</p>

                                                <ul className="Number_list">

                                                    <li>Rush Hours (6-9 am and 3-7 pm): 10 minutes</li>
                                                    <li>Midday & Early Evening: 15 minutes</li>
                                                    <li>After 8:30 pm Weekdays and on Weekends:  20 minutes</li>



                                                </ul>

                                                <p className="lead">Train Service</p>

                                                <ul className="Number_list">

                                                    <li>Hours  Weekdays:  4:45 am to 1am</li>
                                                    <li>Weekends:  6 am to 1am</li>




                                                </ul>

                                                <br />

                                                <ul className="Number_list">

                                                    <li>
                                                        Using one of the automated ticket machines, purchase a ticket to ride.

                                                        <ul>


                                                            <li>You will need to buy to reloadable “Breeze” card for $2.  A SEPARATE Breeze card is required for each passenger.</li>
                                                            <li>Then, you will need to load a trip on to the card for $2.50</li>
                                                        </ul>

                                                        <li>NOTE: Keep the Breeze card with you, you can use it load trips on it whenever you travel in MARTA trains.</li>
                                                        <li>The train ride is approximately 45 minutes</li>
                                                        <li>Doraville station address is 6000 New Peachtree Rd, Doraville, GA 30340</li>
                                                        <li>Once you exit the Doraville train station, please follow the Uber/Lyft directions to get a taxi ride to Yugal Kunj.</li>
                                                        <li>The taxi ride from Doraville station to Yugal Kunj is about 20 minutes.</li>



                                                    </li>
                                                </ul>

                                                <br />


                                                <h5 style={{ fontWeight: "bolder", marginBottom: "10px" }}> We are looking forward to having you join us for the celebrations!</h5>

                                                <label className="alert alert-primary">If you have any questions on traveling to Yugal Kunj, please contact Roshni Masand at roshni.masand@gmail.com</label>

                                            </div>

                                        </div>



                                    </div>



                                </div>



                            </div>

                        </div>


                    </div>


                </div>
            </section>



            <Footer />

        </>


    )


}

export default Directions;