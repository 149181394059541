import Footer from "./Component/Footer";
// import Header from "./Component/Header";

import { NavLink } from 'react-router-dom'
import Container from 'react-bootstrap/Container';

// import OurEventCards from './Component/OurEventCards';
import OverEventTitleCards from './Component/OverEventTitleCards';
// import EventSchedules from './Component/EventSchedules';
import OurEventData from './Component/OurEventData';
import MenuBar from "./Component/MenuBar";



const OurEvent = () => {

    return (

        <>


            <MenuBar />
            {/* breadcrumb start here */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>
                <div className="upper-icon1"></div>
                <div className="auto-container">
                    <h1>Upcoming Events</h1>
                </div>
                <div className="upper-icon1"></div>
                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>
                            <li className="active">Events</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/*  breadcrum end here */}

            <Container>

                {OurEventData.map((val, index) => {
                    return (

                        <OverEventTitleCards
                            key={val.id}
                            title={val.title}
                            links={val.links}
                            lablename={val.lablename}

                            imgscr={val.imgsrc}
                            imglinks={val.imglinks}

                        />


                    )

                })}





            </Container>




            <Footer />
        </>


    )




}

export default OurEvent;