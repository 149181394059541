// import ServiceCards from "./ServiceCards";
// import ServiceData from "./ServiceData";



const Services = () => {

  // console.log(ServiceData[0].Desc);
  return (
    <>
      <section className="causes-section1">
        <div className="icon-one1 paroller1" data-paroller-factor="-0.20" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.10" data-paroller-type="foreground" data-paroller-direction="horizontal" style={{ transform: "translateX(29px)", transition: "transform 0s linear 0s", willChange: "transform" }}><span className="flaticon-heart-2"></span></div>



        <div className="auto-container">

          <div className="sec-title centered">
            <div className="upper-icon1"></div>
            <h2>Yugal Kunj  Services  </h2>

          </div>

          <div className="row row-flex clearfix">

            {/* <!--Cause Block--> */}

            <div class="cause-block1 col-lg-4 col-md-6 col-sm-12 offset-2">
              <div class="inner-box1 wow fadeInUp animated">

                <div class="image-box1"><figure class="image1">
                  <a href="https://www.radhamadhavsociety.org/"
                  >
                    <img src="https://yugalkunjtoronto.org/images/resource/inspirational-1.jpg" alt="" /></a>
                </figure></div><div class="lower-content1"><h3><a href="campinfo.html">  Temple/Spiritual Activities:</a>
                </h3><div class="text1">Daily prayers and aarti, weekly Sunday satsang and celebration of major festivals
                    <a href="https://www.radhamadhavsociety.org/" class="readmore"> Read More</a>
                  </div><a href="https://www.radhamadhavsociety.org/" target="_blank" class="theme-btn1 btn-style-two1">
                    <span class="btn-title1"><span class="icon fa fa-sign-in-alt" aria-hidden="true"></span> Register </span>
                  </a>
                </div>
              </div>
            </div>


            <div class="cause-block1 col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box1 wow fadeInUp animated"

              ><div class="image-box1"><figure class="image1">

                <a href="https://ykportal.yugalkunjtoronto.org/">
                  <img src="https://yugalkunjtoronto.org/images/main-slider/torento1.jpg" alt="" /></a>
              </figure></div><div class="lower-content1"><h3><a href="#">  Yugalkunj Visit</a></h3>
                  <div class="text1">Click here if you are visiting yugalkunj

                    <a href="#" class="readmore"> Read More</a>
                  </div><a href="#" target="_blank" class="theme-btn1 btn-style-two1"><span class="btn-title1"><span class="icon fa fa-sign-in-alt" aria-hidden="true"></span> Register </span></a></div></div></div>




            {/* {ServiceData.map((val, index) => {
              return (

                <ServiceCards
                  key={val.id}
                  imgsrc={val.imgsrc}
                  title={val.title}
                  Desc={val.Desc}
                  Link={val.Link}
                  Readbtn={val.Readbtn}
                  registerbtn={val.registerbtn}


                />
              );

            })} */}


          </div>

        </div>
      </section>

    </>


  )


}

export default Services;