import { useNavigate } from "react-router-dom"




const NotFound = () =>{
   const navigate = useNavigate();
   const onBackClick = (e) => {
      e.preventDefault();
      //navigate(-1);
      navigate("/");
  }

    return (
       <>
       
       <h1 style={{position:'absolute', margin:' 50px auto', color:'red', left:'35%' }}> OOps ! Page Not Found </h1>  
       <a onClick={onBackClick}  className="btn btn-success" style={{position:'absolute', margin:' 110px auto', color:'#fff', left:'35%' }}>Back To Home</a>
       </>


    )



}

export default NotFound;