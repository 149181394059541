// import Header from './Component/Header';
import Footer from './Component/Footer';
import MenuBar from './Component/MenuBar';
import { NavLink } from 'react-router-dom'


const WordsOfWisdom = () => {
    return (
        <>


            {/* <Header/> */}

            <MenuBar />

            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>Words of Wisdom</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>
                            <li className="active">
                                Words of Wisdom
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}

            {/* <!--Sidebar Page Container--> */}
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">

                        {/* <!--Content Side / Blog Detail--> */}
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="post-details">
                                {/* <!--Blog Details--> */}
                                <div className="blog-details">


                                    <div className="lower-box">


                                        <div className="panel panel-primary">
                                            <div className="panel panel-heading">
                                                <h4>Words of Wisdom - Question & Answer Session - #101 </h4>
                                            </div>

                                            <div className="panel panel-body">
                                                <div className='row'>
                                                    <div class="col-md-12">
                                                        {/* <!--<p> <img src="images/wordofwisdom.jpg" /></p>--> */}

                                                        <p> Dear readers, it is my utmost pleasure to present a series of Q&amp;A
                                                            sessions between Shri Maharaj Ji and devotees. </p>
                                                        <p> <strong> Devotee:</strong> Maharaj Ji, what is divine happiness ?  </p>
                                                        <p>
                                                            <strong> Maharaj Ji:</strong>It is unlimited. It always remains with you once you attain it.
                                                            It’s the happiness that the soul is always searching for.
                                                            In the material world happiness is limited and short lived.



                                                        </p>


                                                        <p> <strong> Devotee:</strong> Maharaj Ji, what is Yogmaya?
                                                        </p>

                                                        <p>
                                                            <strong> Maharaj Ji:</strong> Yogmaya is a divine power of God that enables God to perform worldly works while remaining internally divine. God creates universes and takes note of countless actions of innumerable souls yet remains ever blissful. Yogmaya is the reason behind this. God gives the same Yogmaya to those who surrender fully.
                                                            Consequently, all God-realized saints always remain blissful.

                                                            <br />
                                                            Look at it this way. You are under Maya’s control, yet you are capable of doing so much.
                                                            When driving, you are looking at the traffic ahead and behind you.
                                                            You are talking to the passengers in your car at the same time.
                                                            You have your destination in mind and simultaneously you are assessing the traffic situation.

                                                            Your hands are on the steering wheel and your foot is on the brakes. You are doing all this work at the same time.
                                                            Then what is the difficulty for God and Godly saints to perform worldly works and simultaneously remain divine internally?


                                                        </p>

                                                        <p> <strong> Devotee:</strong> Maharaj Ji, do God-realized saints taste fruits of their actions like ordinary people?
                                                        </p>

                                                        <p>
                                                            <strong> Maharaj Ji:</strong> There are two kinds of Maya. One is the tri-colored Maya with its three modes – mode of virtue, mode of worldly passions and desires, and mode of ignorance. (Sattva Gun, Rajo Gun, Tamo Gun) The other Maya is the divine power of God called Yogmaya. The works of both look the same. Just as an ordinary person performs actions using sense organs, so does a saint.
                                                            However, his actions are performed with Yogmaya; therefore, he does not taste fruits of his actions. Actions performed by an ordinary person are the works of Maya. The individual becomes attached to these actions and these actions bear fruits. The one who has attained God-realization remains attached only to God while performing works of Yogmaya. He does not reap consequences of his actions.




                                                        </p>


                                                        <hr />
                                                        <p> Yours,</p>
                                                        <p> Didi Ji</p>
                                                        <p class="text-center"> Note: You may direct your spiritual questions to me
                                                            directly at</p>
                                                        <p class="text-center"> s_didi@radhamadhavsociety.org</p>


                                                    </div>

                                                </div>

                                            </div>


                                        </div>




                                    </div>






                                </div>





                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {/* <!-- End Sidebar Page Container --> */}




            <Footer />

        </>


    )


}

export default WordsOfWisdom;