import Carousel from 'react-bootstrap/Carousel';

function Slider() {
  return (

    <section className="coming-events">

      <div className="auto-container">
        <div className="sec-title centered">
          <div className="upper-icon"></div>
          <h2> Upcoming Events </h2>



        </div>

        <Carousel variant="dark">
          <Carousel.Item >
            <img
              className="d-block w-100"
              src="https://yugalkunj.org/images//Event/DiwakariDidiFlyer-Aug2023.jpg"
              alt="First slide"
            />
            {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item >
            <img
              className="d-block w-100"
              src="https://yugalkunj.org/images//Event/LaborDayRetreatFlyer.jpg"
              alt="Second slide"
            />
            {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://yugalkunj.org/images//Event/JanmashtamiCelebrations2023.jpg"
              alt="Third slide"
            />
            {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
          </Carousel.Item>


          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://yugalkunj.org/images//Event/GurukulFlyers-ATL.jpg"
              alt="Third slide"
            />
            {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://yugalkunj.org/images//Event/HindiFlyers.jpg"
              alt="Third slide"
            />
            {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
          </Carousel.Item>




          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://yugalkunj.org/images//Event/PRS-Adult.jpg"
              alt="Third slide"
            />
            {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
          </Carousel.Item>



        </Carousel>

      </div>
    </section>




  );
}

export default Slider;