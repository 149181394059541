// import Header from "./Component/Header"; 
import MenuBar from './Component/MenuBar';
import Footer from './Component/Footer';

import { Link, NavLink } from 'react-router-dom'

const AboutPage = () => {
    return (

        <>

            {/* <Header/> */}

            <MenuBar />

            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>About Us  </h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>

                            <li className="active">About</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}

            {/* <!--About Section--> */}
            <section className="about-section">

                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <!--Left Column--> */}
                        <div className="left-column col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="sec-title">
                                    <div className="sub-title"><span className="icon"></span> </div>
                                    <h2>Welcome To Yugal Kunj</h2>
                                </div>
                                <div className="lower-box">
                                    {/* <!--<h4>Meaning Of The Name</h4>--> */}
                                    <h4>Meaning of Yugal Kunj</h4>

                                    <div className="text">
                                        <p>The name was given by our Gurudev, Jagadguru Shri Kripalu Ji Maharaj. The meaning of YUGAL KUNJ is the ‘flowery pleasure groves of Vrindavan where Radha and Krishna roam leisurely and perform loving pastimes.’</p>
                                        <h4>Aim Of Yugal Kunj</h4>

                                        <p>


                                        </p>

                                        <p> Spread over 15 acres of lush green landscape located Duluth Atlanta, Yugal Kunj is dedicated to bring religion and faith to people of all ages. Yugal Kunj aspires to provide a temple for practicing devotion; a community center for the personal development of children and adults, and a second home where families can spend quality time together in a picturesque serene surrounding.</p>
                                        {/* 
                                    <p>
                                        Spread over 15 acres of lush green landscape located Duluth Atlanta, Yugal Kunj is dedicated  to bring religion and faith to people of all ages.
                                        Yugal Kunj aspires to provide a temple for practicing devotion; a community center for the personal development of children and adults,
                                        and a second home where families can spend quality time together in a picturesque serene surrounding.
                                    </p> */}

                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <!--Right Column--> */}
                        <div className="right-column col-lg-5 col-md-12 col-sm-12">
                            <div className="about-feature-box">
                                <div className="outer clearfix">
                                    {/* <!--About Feature--> */}
                                    <div className="about-feature col-md-6 col-sm-12">
                                        <div className="inner-box">
                                            <div className="content-box">
                                                <div className="inner">
                                                    {/* <!-- <h3><a href="#">Truly Get Inspired From Us</a></h3>--> */}

                                                    <h3><a href="#">Get Truly Inspired By Us</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--About Feature--> */}
                                    <div className="about-feature col-md-6 col-sm-12">
                                        <div className="inner-box">
                                            <div className="content-box">
                                                <div className="inner">
                                                    <h3><Link to='/USA/VolunteerRegistration'>Become a Genuine Volunteer</Link></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--About Feature--> */}
                                    <div className="about-feature col-md-6 col-sm-12">
                                        <div className="inner-box">
                                            <div className="content-box">
                                                <div className="inner">
                                                    <h3><a href="https://payments.paysimple.com/Buyer/CheckOutFormPay/wTEp3uzwUq544RHSP02hH7cz81k-">Make a Donation</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--About Feature--> */}
                                    <div className="about-feature col-md-6 col-sm-12">
                                        <div className="inner-box">
                                            <div className="content-box">
                                                <div className="inner">
                                                    <h3><a href="#">Others</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <!--  secton section --> */}


                </div>

            </section>
            <section className="about-section-two">

                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <!--Text Column--> */}
                        <div className="text-column col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="sec-title">
                                    {/* <!--  <div className="sub-title"><span className="icon"></span> We believe what we achieve</div> --> */}
                                    <h2>About The Organization</h2>
                                </div>
                                <div className="lower-box">

                                    <div className="text">
                                        Radha Madhav Society is part of the worldwide mission of Jagadguru Kripalu Parishat (JKP), a non-profit organization founded by Jagadguru  Shri Kripalu ji Maharaj to provide a deeper understanding of true Hinduism and bring to light the resources of this eternal religion and its all-encompassing culture. The temple is an initiative of Sushri Siddheshvari Devi (Didi Ji), a senior disciple of Jagadguru Shri Kripalu Ji Maharaj.  Didi Ji  is well-known to the local Atlanta population as well as a respected figure in the US, Canada, Trinidad and other parts of the world.
                                        <br />
                                        Spread over 15 acres of lush green landscape located  Duluth Atlanta, Yugal Kunj is dedicated to bring religion and faith to people of all ages. Yugal Kunj  provide a temple for practicing devotion; a community center for the personal development of children and adults, and a second home where families can spend quality time together in a  spiritual serene surrounding.


                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--Image Column--> */}
                        <div className="image-column col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <figure className="image-box">
                                    <img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/featured-image-5.jpg" alt="" /></figure>
                            </div>
                        </div>
                    </div>


                    <div className="row clearfix">
                        <div className="col-md-12">

                            <div className="lower-box">
                                <h4>Concept Of Yugal Kunj</h4>

                                <p>
                                    The concept of Yugal Kunj is quite unique. It emphasizes religion in a new and modern way while firmly holding on to the  values of Hinduism. The building itself is a modern take on a traditional temple. The elegant lobby with its jeweled tone décor leads to a grand Prayer Hall that houses the main shrine for the beautiful deities of Radha Krishna and Sita-Ram. The deities are handcrafted in pure white marble and look resplendent in glamorous outfits and jewels. The beauty and divinity of the deities practically help devotees with meditation and devotion to God.
                                </p>





                                {/* <!--<h4>Surroundings</h4>--> */}

                                <p>
                                    The building is surrounded by lush greenery that truly gives one the feel of a “Kunj” (flowery bower) but sits in the middle of Duluth, making it easily accessible from various locations within the city. Once you enter the grounds, the feeling is like no other. It is a place filled with trees and flowers that mesmerize you and will compel you to spend a little more time.

                                </p>
                                <p> This center focuses very strongly on the youth.Through its various activities and services Yugal Kunj  provide a positive, safe and healthy place for the youth to come together on common ground that centers on Hinduism and Hindu culture.</p>

                                <p> Gurukul is the educational wing of Yugal Kunj. This exciting initiative will take children and teens step by step through the various phases of our culture, helping them develop long lasting faith and understanding of the Hindu religion. Beautiful classrooms provide an optimal learning environment. and dedicated teachers make learning an enjoyable experience for all children.</p>


                                <h4>For The Youth</h4>

                                <p>


                                    The temple and community center  a wide variety of projects and subjects for the youth, such as robotics, dance, art, music, and Indian languages. There is something here for everyone. A world class, unique electronic interactive playground,  the pangs of separation from technology. baseball courts, tennis courts and a jungle gym are all.
                                </p>

                                <p> There are various activities in progress at the center. The youth are being taught to play various musical instruments depending on their choice and aptitude. They learn to play traditional instruments such as dholak and harmonium and untraditional ones such as danthal.  Movie nights with popcorn, and picnics in the green surroundings are much loved by kids of all ages. These events give parents a chance to soak in the serene atmosphere of the temple while the kids spend time in a positive and nurturing environment.</p>

                                <h4>Gurukul</h4>




                                <p>
                                    The temple and community center provides a wide variety of projects and subjects for the youth, such as robotics, dance,  art,  music, and Indian languages. There is something here for everyone. All one needs to bring is an interest, an open mind and a sense of commitment. We are proud to provide a world class, unique electronic interactive playground. In a technology driven world it is an innovative way to physically challenge children .  In addition we offer baseball courts, tennis courts and a jungle gym.
                                </p>

                                <p> The youth are taught to play various musical instruments depending on their choice and aptitude. They learn to play traditional instruments such as dholak and harmonium and untraditional ones such as danthal. </p>
                                <p> Movie nights with popcorn, and picnics in the green surroundings are much loved by kids of all ages. These events give parents a chance to enjoy the serene atmosphere of the temple while the kids spend time in a positive and fun environment</p>

                                <h4>Camps</h4>
                                <p>
                                    Camps foster group activities where children develop a sense of teamwork and the spirit of sharing. Day Camps, organized throughout the year, are fun filled days, activities range from water sports to s’mores around the campfire.  Siddheshvari DidiJi plays an active role in these camps and her presence and association naturally develops into a strong bond with the children. This bond plays an important role in the child’s spiritual development.  Older campers also learn various aspects of leadership and project management, which makes them better leaders in the future.
                                </p>

                                <h4>For Adults</h4>

                                <p>
                                    Although youths are at the center of this community center, there is a strong framework to include the young at heart.
                                </p>

                                <p> There is an abundance of resources available to adult members. Daily satsangs and kirtans are followed by inspiring lectures and discussions.  Geeta classes and book </p>
                                <p> clubs are also offered. People can have enlighting discussions on the beautiful walking trails or sit in meditation by the quiet reflection pool. The choices are plenty and yours to make.</p>

                                <p> Adults are encouraged to learn Yoga and to play musical instruments.  Ayurved and homeopathic approaches are available to provide safe medical alternatives.  Holistic massages are also offered.  A resident chef prepares nourishing juices and power smoothies and wholesome fresh “sattvik” meals for a nominal cost.</p>

                                {/* <!--<h4>For Seniors</h4>

                <p>
                    Yugal Kunj provides seniors with a place that is safe and up to code for the physically challenged.  Seniors get a great blend of Bhakti (devotion) and seva (social service) while associating with friends and likeminded persons in a beautiful natural setting
                    Yugal Kunj provides the older generation with an amazing opportunity to mentor the younger generation. Here dadi ma’s pickle recipe will be shared and taught to the younger generation and will in this way live forever. Grandmothers proudly teach the art of knitting and crocheting to the youngsters, while the younger generation takes up the challenge of introducing Facebook and iTunes to the older generation. This cross mentoring benefits the entire community.




                </p>--> */}

                            </div>
                        </div>

                    </div>
                </div>

            </section>


            <section className="call-to-action alternate-two">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-pattern-1.png)' }}></div>

                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="title-column col-xl-9 col-lg-12 col-md-12 col-sm-12">

                            <h2>  Become a vital part of Yugal Kunj &amp;  make a real difference to lives of many!</h2>
                        </div>
                        <div className="link-column col-xl-3 col-lg-12 col-md-12 col-sm-12">
                            <div className="link-box">
                                <a href="volunteer-registration.html" className="theme-btn btn-style-one"><span className="btn-title">join to Volunteer</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>


    )


}

export default AboutPage;