// import Header from './Component/Header';
import Footer from './Component/Footer';
import MenuBar from './Component/MenuBar';

import { Link } from 'react-router-dom'

const WordsOfWisdom = () => {
    return (
        <>


            {/* <Header/> */}

            <MenuBar />

            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>Words of Wisdom</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>
                            <li className="active">
                                Words of Wisdom
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}

            {/* <!--Sidebar Page Container--> */}
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">

                        {/* <!--Content Side / Blog Detail--> */}
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="post-details">
                                {/* <!--Blog Details--> */}
                                <div className="blog-details">






                                    <div className="lower-box">


                                        <div class="panel panel-primary">
                                            <div class="panel panel-heading">
                                                <h4>Words of Wisdom - Question &amp; Answer Session - #99 </h4>


                                            </div>

                                            <div class="panel panel-body" style={{ backgroundColor: '#fff' }}>
                                                <div class="row">
                                                    <div class="col-md-12">


                                                        <p> Dear readers, it is my utmost pleasure to present a series of QA sessions between Shri Maharaj Ji and devotees.</p>
                                                        <p> <strong> Devotee:</strong>  Maharaj Ji, what is the difference between Attachment and Love?</p>
                                                        <p>
                                                            <strong> Maharaj Ji:</strong>The material intellect is convinced that there is happiness in the world. It has firmly decided that while we have not yet received happiness, happiness does exist in the world. This is why the mind has been running towards the world since we have been born, and it will continue to do so until we die. Consequently, we have been experiencing sorrows, restlessness, torments of hell and temporary pleasures of Svarg. The love of the mind for objects and individuals of the world is called Attachment.
                                                            <br />
                                                            Now, there is the second area remaining; that of God and Saints. These two are pure personalities. If the mind gets attached to these two, that attachment is called Love. Becoming attached to God and Godly saints is called Love, while loving things and people of the world is called Attachment.
                                                            <br />
                                                            Gaurang Mahaprabhu says that worldly attachments are like utter darkness while love for God is like bright sunshine.


                                                        </p>

                                                        <p> <strong> Devotee:</strong> Maharaj Ji, kindly explain the words, ‘Apani Or Nihaariye,’ (Look towards Yourself, Radha Rani). </p>

                                                        <p>
                                                            <strong>Maharaj Ji: </strong>  The meaning is - O Radha Rani! You are causelessly merciful. Think about Your nature; that of bestowing grace causelessly. Do not look at me. Do not think about whether I am deserving of Your grace. Good or bad, I am Your child. You must take complete care of me. A newborn child does not recognize its mother, but the mother does not find fault with the child for not recognizing her. She does not say, “You were in my womb for nine months; how is it that you do not recognize me?”


                                                            <br />

                                                            The devotee says, “O Radha Rani! Take Your causelessly merciful nature into consideration and give me Your unconditional grace. Do not give any thought to my sins.”
                                                        </p>

                                                        <p><stong> Devotee: </stong>  Maharaj Ji, what is the best seva (devotional service)?</p>

                                                        <p> <strong> Maharaj Ji: </strong>  Taking initiative and serving physically, financially, and mentally is first class seva. Serving happily when instructed by one’s Guru, is second class seva. Serving begrudgingly when instructed by the Guru, is third class seva.</p>

                                                        <hr />
                                                        <p> Yours,</p>
                                                        <p> Didi Ji</p>
                                                        <p class="text-center"> Note: You may direct your spiritual questions to me directly at</p>
                                                        <p class="text-center"> s_didi@radhamadhavsociety.org</p>


                                                    </div>

                                                </div>

                                            </div>


                                        </div>




                                    </div>




                                    {/* 
                                <div className="share-post clearfix">
                                    <div className="share-title">Share This</div>
                                    <ul className="social-links clearfix">
                                        <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                        <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                                        <li><a href="#"><span className="fab fa-instagram"></span></a></li>
                                        <li><a href="#"><span className="fab fa-youtube"></span></a></li>
                                    </ul>
                                </div> */}
                                </div>






                            </div>

                        </div>
                        {/* <!--End Content Side-->
                    <!--Sidebar Side--> */}

                        {/* <!--End Sidebar Side--> */}
                    </div>
                </div>
            </div>
            {/* <!-- End Sidebar Page Container --> */}




            <Footer />

        </>


    )


}

export default WordsOfWisdom;