import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState } from 'react'




const MenuBar = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);




  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = (e) => {
    setShow(false);
  }
  const showDropdown1 = (e) => {
    setShow1(!show1);
  }
  const hideDropdown1 = (e) => {
    setShow1(false);
  }



  const showDropdown2 = (e) => {
    setShow2(!show2);
  }
  const hideDropdown2 = (e) => {
    setShow2(false);
  }

  const showDropdown3 = (e) => {
    setShow3(!show3);
  }
  const hideDropdown3 = (e) => {
    setShow3(false);
  }
  const showDropdown4 = (e) => {
    setShow4(!show4);
  }
  const hideDropdown4 = (e) => {
    setShow4(false);
  }


  const showDropdown5 = (e) => {
    setShow5(!show5);
  }
  const hideDropdown5 = (e) => {
    setShow5(false);
  }



  return (

    <>

      {/* <!-- Header Top --> */}


      <Navbar collapseOnSelect expand="lg" fixed='top' className='navbarbg'>
        <Container>

          <Navbar.Brand href="/" className='logo'>
            <img src='https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/logo.png' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav className="me-auto">
              <Nav.Link href="/USA/Home">Home </Nav.Link>
              {/* <Nav.Link href="/MyGallery" >Gallery</Nav.Link> */}
              <Nav.Link href="/USA/about">About Us</Nav.Link>
              <NavDropdown title="DIDI JI " className="dropdown" id="collasible-nav-dropdown" show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}>
                <NavDropdown.Item href="/USA/aboutdidiji" >About Didi Ji</NavDropdown.Item>
                <NavDropdown.Item href="/USA/videolectures">
                  Video Lectures
                </NavDropdown.Item>
                <NavDropdown.Item href="/USA/wordsofwisdom">Words of Wisdom</NavDropdown.Item>


              </NavDropdown>

              <Nav.Link href="/USA/Gallery">GALLERY</Nav.Link>
              <NavDropdown title="REGISTRATION " id="collasible-nav-dropdown" show={show1}
                onMouseEnter={showDropdown1}
                onMouseLeave={hideDropdown1}>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/" >Retreat</NavDropdown.Item>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/">
                  Camp
                </NavDropdown.Item>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/">Class</NavDropdown.Item>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/">Cooking & Prasad Seva</NavDropdown.Item>


              </NavDropdown>
              {/* <NavDropdown title="Seva " id="collasible-nav-dropdown" show={show2}
                onMouseEnter={showDropdown2}
                onMouseLeave={hideDropdown2}>
                <NavDropdown.Item href="ykportal.yugalkunj.org" target="_blank" > Cooking & Prasad seva </NavDropdown.Item>




              </NavDropdown> */}


              <NavDropdown title="Event" id="collasible-nav-dropdown" show={show3}
                onMouseEnter={showDropdown3}
                onMouseLeave={hideDropdown3}>
                <NavDropdown.Item href="/USA/upcomingevents" >Upcoming Events</NavDropdown.Item>
                <NavDropdown.Item href="/USA/Blogs" >Our Blogs</NavDropdown.Item>
                <NavDropdown.Item href="/USA/templeactivities">
                  Temple Activities
                </NavDropdown.Item>
                <NavDropdown.Item href="/USA/activitycalendar">Yugal Kunj Calendar</NavDropdown.Item>


              </NavDropdown>
              <NavDropdown title="VOLUNTEER " id="collasible-nav-dropdown" show={show4}
                onMouseEnter={showDropdown4}
                onMouseLeave={hideDropdown4}>
                <NavDropdown.Item href="/USA/volunteerregistration" >Volunteer Registration</NavDropdown.Item>
                <NavDropdown.Item href="https://payments.paysimple.com/Buyer/CheckOutFormPay/wTEp3uzwUq544RHSP02hH7cz81k-">
                  Donate
                </NavDropdown.Item>



              </NavDropdown>

              <NavDropdown title="FACILITIES " id="collasible-nav-dropdown" show={show5}
                onMouseEnter={showDropdown5}
                onMouseLeave={hideDropdown5}>
                <NavDropdown.Item href="/USA/directions" >Directions</NavDropdown.Item>
                <NavDropdown.Item href="/USA/eventrentals">
                  Event Rentals
                </NavDropdown.Item>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/Tiffin/Home">Catering & Dining</NavDropdown.Item>
                <NavDropdown.Item href="/USA/contact">Contact</NavDropdown.Item>




              </NavDropdown>

              <div class="donate-link">
                <a href="https://payments.paysimple.com/Buyer/CheckOutFormPay/wTEp3uzwUq544RHSP02hH7cz81k-" class="theme-btn btn-style-one" target='_blank'><span class="btn-title">Donate
                  Now</span></a>
              </div>
            </Nav>

          </Navbar.Collapse>

        </Container>
      </Navbar>



    </>

  )


}

export default MenuBar;