import GalleryCard from "./GalleryCard";
import GalleryCardData from "./GalleryCardData";
import {Link } from 'react-router-dom'

const Gallery = () =>{
  return(

 <>
 


<section className="activity-section">

<div className="auto-container">
    <div className="sec-title centered">
        <div className="upper-icon"></div>
        <h2>Our Gallery</h2>
        {/* <!-- <div className="text">We believe what we achieve</div> --> */}
    </div>

    <div className="mixit-gallery">
        {/* <!--Filter--> */}
        {/* <div className="filters text-center clearfix">
            <ul className="filter-tabs filter-btns clearfix">
                <li className="filter active" data-role="button" data-filter="all"><span className="txt">All</span></li>
                <li className="filter" data-role="button" data-filter=".medical"><span className="txt">Events</span></li>
                <li className="filter" data-role="button" data-filter=".education"><span className="txt">Festivals</span></li>
                <li className="filter" data-role="button" data-filter=".animal"><span className="txt">Celebration</span></li>
                <li className="filter" data-role="button" data-filter=".shelter"><span className="txt"> Annual Function</span></li>

                <li className="filter" data-role="button" data-filter=".retreat"><span className="txt"> Spring Retreat</span></li>

                

            </ul>
        </div> */}


        <div className="filter-list row clearfix" id="MixItUp7B5F74">

           
            

            {/* <GalleryCard 
              imgsrc="https://stssevastorage.blob.core.windows.net/retreat/r1.JPG"
              Link="https://stssevastorage.blob.core.windows.net/retreat/r1.JPG"
            /> */}

{GalleryCardData.map(  (val, index) =>{
    return (

        <GalleryCard 
        key={val.id}

        imgsrc={val.imgsrc}
        Link={val.Link}

        
      />
    );

  })}



        </div>


        <div className="bottom-info">

            <div className="link-box"><a href="gallery.html" className="theme-btn btn-style-five"><span className="btn-title">View More Activities</span></a></div>
        </div>

    </div>
</div>
</section>
 </>
  )

}

export default Gallery;