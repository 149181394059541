
import ServiceCards from "./ServiceCards";
import ServiceData from "./ServiceData";



const Services = () => {


    // console.log(ServiceData[0].Desc);
   return (
        <>
              <section className="causes-section1">
      <div className="icon-one1 paroller1" data-paroller-factor="-0.20" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.10" data-paroller-type="foreground" data-paroller-direction="horizontal" style={{transform:"translateX(29px)" , transition: "transform 0s linear 0s", willChange: "transform"}}><span className="flaticon-heart-2"></span></div>
  
     
  
      <div className="auto-container">
  
          <div className="sec-title centered">
              <div className="upper-icon1"></div>
              <h2>Yugal Kunj Services  </h2>
  
          </div>
  
          <div className="row row-flex clearfix">
  
    {/* <!--Cause Block--> */}
    
           

  
  {ServiceData.map(  (val, index) =>{
    return (

      
      
        <ServiceCards 
        key={val.id}
         imgsrc={val.imgsrc} 
         title={val.title}                  
         Desc={val.Desc} 
         Link={val.Link} 
         Readbtn ={val.Readbtn} 
         RegistrationLink={val.RegistrationLink}
         registerbtn ={val.registerbtn} 
        
         
   
       />

       

       
    );

  })}
  
  
          </div>
  
      </div>
              </section>
        
        </>


   )


}

export default Services;