import Home from './USA/Component/Home'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'


import AboutPage from './USA/AboutPage';
import Contact from './USA/Contact';
import OurGallery from './USA/OurGallery';
import AboutDidiJi from './USA/AboutDidiJi';
import VideoLectures from './USA/VideoLectures';
import WordsOfWisdom from './USA/WordsOfWisdom';
import TempleActivities from './USA/TempleActivities';
import ActivityCalendar from './USA/ActivityCalendar';
import VolunteerRegistration from './USA/VolunteerRegistration';
import Directions from './USA/Directions';
import EventRentals from './USA/EventRentals';
import OurEvent from './USA/OurEvent'
import CandaHome from './Canada/Component/Home'
import MyGallery from './USA/MyGallery'
import Blogs from './USA/Blogs'
import BlogDetails from './USA/BlogDetails'
import Camp from './USA/Camp/SummerCamp'
import RetreatCamp from './USA/Camp/RetreatCamp'




//  canada website router name 
import CanadaAbout from './Canada/AboutPage'
import CanadaAboutDidiJi from './Canada/AboutDidiJi'
import CanadaVideoLectures from './Canada/VideoLectures';
import CanadaContact from './Canada/Contact'
import CanadaDirection from './Canada/Directions'
import CanadaActivityCalendar from './Canada/ActivityCalendar'
import CanadaEventRentals from './Canada/EventRentals'
import CanadaOurEvent from './Canada/OurEvent'
import CanadaOurGallery from './Canada/OurGallery'
import CanadaOurServices from './Canada/OurServices'
import CanadaVolunteerRegistration from './Canada/VolunteerRegistration'
import CanadaWordsOfWisdom from './Canada/WordsOfWisdom'
import CanadaTempleActivities from './Canada/TempleActivies';

import SelectCountry from './Country/SelectCountry'

import NotFound from './NotFound'


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'



const App = () => {


  return (



    <Router>

      <>

        <Routes>

          <Route path='*' element={<NotFound />} />

          <Route path='/' element={<SelectCountry />} />

          <Route path='/USA/Home' element={<Home />} />



          <Route path='/USA/about' element={<AboutPage />} />

          <Route path='/USA/ourGallery' element={<OurGallery />} />

          <Route path='/USA/gallery' element={<OurGallery />} />
          <Route path='/USA/aboutDidiJi' element={<AboutDidiJi />} />
          <Route path='/USA/contact' element={<Contact />} />
          <Route path='/USA/videoLectures' element={<VideoLectures />} />
          <Route path='/USA/wordsofwisdom' element={<WordsOfWisdom />} />
          <Route path='/USA/templeactivities' element={<TempleActivities />} />
          <Route path='/USA/activitycalendar' element={<ActivityCalendar />} />
          <Route path='/USA/VolunteerRegistration' element={<VolunteerRegistration />} />
          <Route path='/USA/directions' element={<Directions />} />
          <Route path='/USA/eventrentals' element={<EventRentals />} />
          <Route path='/USA/upcomingevents' element={<OurEvent />} />
          <Route path='/USA/Blogs' element={<Blogs />} />
          <Route path='/USA/BlogDetails' element={<BlogDetails />} />
          <Route path='/USA/Camp/SummerCamp' element={<Camp />} />
          <Route path='/USA/Camp/RetreatCamp' element={<RetreatCamp />} />




          <Route path='/MyGallery' element={<MyGallery />} />


          {/* Canada Compoenet Router path */}

          <Route path='/CAD/Home' element={<CandaHome />} />
          <Route path='/CAD/about' element={<CanadaAbout />} />

          <Route path='/CAD/AboutDidiJi' element={<CanadaAboutDidiJi />} />
          <Route path='/CAD/VideoLectures' element={<CanadaVideoLectures />} />
          <Route path='/CAD/Contact' element={<CanadaContact />} />
          <Route path='/CAD/Direction' element={<CanadaDirection />} />
          <Route path='/CAD/ActivityCalendar' element={<CanadaActivityCalendar />} />
          <Route path='/CAD/EventRentals' element={<CanadaEventRentals />} />

          <Route path='/CAD/OurEvent' element={<CanadaOurEvent />} />
          <Route path='/CAD/OurGallery' element={<CanadaOurGallery />} />
          <Route path='/CAD/OurServices' element={<CanadaOurServices />} />
          <Route path='/CAD/VolunteerRegistration' element={<CanadaVolunteerRegistration />} />
          <Route path='/CAD/WordsOfWisdom' element={<CanadaWordsOfWisdom />} />
          <Route path='/CAD/TempleActivities' element={<CanadaTempleActivities />} />



        </Routes>






      </>
    </Router>
  );
}

export default App;
