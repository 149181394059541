const TempleActivities = () => {
  return (
    <>
      {/* <!--Join events Section--> */}
        <section className="join-events">
            {/* <!--Upper Box--> */}
            <div className="upper-box">
                <div className="icon-one paroller" data-paroller-factor="-0.20" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-care"></span></div>

                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <!--Image Column--> */}
                        <div className="image-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <figure className="image wow slideInLeft">
                                    <img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/featured-image-2.jpg" alt=""/></figure>
                            </div>
                        </div>
                        {/* <!--Left Column--> */}
                        <div className="text-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="sec-title">
                                    <div className="sub-title"><span className="icon"></span>
                                     {/* <!-- We believe what we achieve --> */}
                                     </div>
                                    <h2> Join Temple Activities</h2>
                                </div>
                                <div className="text-content">
                                    <div className="text"> The temple have deities of Radha-Krishna and Sita-Ram and is a place of worship that promotes selfless devotion to God. Apart from daily prayers and Artis, the temple aims to have regular satsangs, periodic discourses by preachers of Jagadguru Kripalu Parishat, provide easy access to preacher and priest, literature and devotional media such as books, audio CDs and video DVDs to help individuals and families advance on the spiritual path.</div>
                                    <div className="link-box"><a href="upcoming-event.html" className="default-link">view all events</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--Lower Box--> */}

        </section>
     
    
     </>

  )


}

export default TempleActivities;