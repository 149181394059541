import ReactDOM from "react-dom/client";
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './asstes/css/sass/style.scss'
//import './asstes/css/style.css'

import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(



     <App />



);


