import { NavLink } from "react-router-dom";


const About = () => {
    return (
        <>

            {/* <!--About Section--> */}
            <section className="about-section-four">

                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <!--Text Column--> */}
                        <div className="text-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="sec-title">
                                    <div className="sub-title"><span className="icon"></span> </div>
                                    <h2>Welcome To Yugal Kunj</h2>
                                </div>


                                <div className="text">
                                    <h5> Meaning Of The Name</h5>
                                    <p>Meaning Of The Name
                                        The name was given by our Gurudev, Jagadguru Shri Kripalu Ji Maharaj. The meaning of
                                        YUGAL KUNJ is
                                        the ‘flowery pleasure groves of Vrindavan where Radha and Krishna roam leisurely and
                                        perform loving
                                        pastimes.’ </p>

                                    <h5> Aim Of Yugal Kunj</h5>

                                    <p>Spread over, one full city block of downtown Hamilton, Yugal Kunj brings religion and
                                        culture to people
                                        of all ages. Yugal Kunj provides a temple for practicing devotion, a community
                                        center for the personal
                                        development of children and adults, and a second home where families can spend
                                        quality time together
                                        in a spiritual and socially uplifting environment.</p>

                                </div>
                                <div className="link-box"><NavLink to='/CAD/about' className="default-link">Read More</NavLink></div>

                            </div>
                        </div>
                        {/* <!--Image Column--> */}
                        <div className="image-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image-box">
                                    <figure className="image">
                                        <img src="https://yugalkunjtoronto.org/images/main-slider/torento1.jpg" alt="" /></figure>
                                    <div className="caption-box wow fadeInUp animated" data-wow-delay="0ms" >
                                        <div className="cap-inner">
                                            <h3>#Change Your Thoughts, Change Your Life</h3>
                                            <div className="text">"Inspiring selfless devotion to God as taught by the timeless Vedas and Hindu Scriptures and helping community members of all ages to lead a happy, healthy and spiritually oriented life."</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>


    )


}

export default About;