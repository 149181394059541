const WhatWeDoData = [

    {
        id: 1,
        imgicon: "flaticon-therapy",
        title: "Words Of Wisdom",
        Link: "/CAD/WordsOfWisdom",

        Desc: "Words of Wisdom is inspirational message sent out by Didi Ji every Friday. To receive weekly emails of Words of Wisdom",
        Readtext: "Read More"


    },


    {
        id: 2,
        imgicon: "flaticon-recycle-sign",
        title: "Inspirational Messages",
        Link: "https://www.radhamadhavsociety.org/teachings/daily-inspirations",

        Desc: "Spiritual nuggets from the Words of Wisdom that are written by Didi Ji and sent out every Friday...",

        Readtext: "Read More"


    },



    {
        id: 3,
        imgicon: "flaticon-video-camera",
        title: "Video Of The Month",
        Link: "/CAD/VideoLectures",

        Desc: "A new video by Didi Ji explaining the importance of Temples.",

        Readtext: "Watch Now"


    },

    {
        id: 4,
        imgicon: "flaticon-video-camera",
        title: "Camps",
        Link: "https://ykportal.yugalkunj.org/",

        Desc: "We have already published a list of all retreats that will be organized by RMS in 2021. Please review this list and make your plans now!.",

        Readtext: "Read More"


    },

    {
        id: 5,
        imgicon: "flaticon-donation",
        title: "Donate",
        Link: "http://donation.yugalkunj.org/",

        Desc: "Support our humanitarian and community activities by making a tax-exempt donation.",

        Readtext: "Donate"


    },



    {
        id: 6,
        imgicon: "flaticon-worldwide",
        title: "Kripalu Bhakti Radio",
        Link: "http://radhamadhavsociety.org/audio-video/kripalu-bhakti-radio",

        Desc: "Kripalu Bhakti Radio is brought to you by Radha Madhav Society! This 24/7 internet radio plays devotional songs and lectures by Jagadguru Shri Kripalu Ji Maharaj and Didi Ji.",

        Readtext: "Listen Now"

    }


];
export default WhatWeDoData;

