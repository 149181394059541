import { Link, NavLink } from 'react-router-dom';
import MenuBar from './Component/MenuBar';
import Footer from './Component/Footer';
import NavbarMenu from './Component/NavbarMenu';
// import Header from './Component/Header';


const AboutDidiJi = () => {
    return (
        <>



            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>About Didi Ji</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/CAD/Home">Home</Link></li>

                            <li className="active">About Didi Ji</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}


            {/* <!--About Section--> */}
            <section className="about-section-two">

                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <!--Text Column--> */}
                        <div className="text-column col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="sec-title">
                                    <div className="sub-title"><span className="icon"></span> About Didi Ji</div>
                                    <h2>Sushri Siddheshvari Devi Ji</h2>
                                </div>
                                <div className="lower-box">

                                    <div className="text">

                                        addressed as Didi Ji (respected sister), was born in the state of Punjab in Northern India and spent her formative years in Canada, graduating from York University in Toronto with a degree in linguistics. Even in early childhood, she was drawn to temples and drew great solace from visiting places of worship. While other children were lost in play, she was looking within and contemplating on the purpose of life and the phenomenon of death. Internally guided by God, she intuitively felt that life has a much higher purpose than merely eating, sleeping and accumulating wealth. The promise of a financially rewarding career did not hold any appeal for her.

                                        <br />
                                        After graduating from university, Didi Ji's spiritual quest led her to Jagadguru Shri Kripalu Ji Maharaj.  Shri Maharaj Ji is only the fifth personality in the past 5,000 years to be recognized as an original Jagadguru, i.e. Guru of the world. Didi Ji made a pilgrimage to India where she spent two years in Guru Ashram and was taught the intricate mysteries of the Vedas and other scriptures by Shri Kripalu Ji Maharaj Himself. She started preaching service in 1987.





                                    </div>
                                </div>
                                <div className="quote-box">
                                    <div className="quote-text">
                                        “The responsibiltry for our actions rests with us .Yet, we cannot
                                        do anything without God gracing us with mind and intellect.
                                        So, God enables us to perform actions, but
                                        we decide what we are going to do ”
                                    </div>
                                    <div className="quote-info">

                                        <div className="author-title">
                                            Sushri Siddheshvari Devi Ji
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Image Column--> */}
                        <div className="image-column col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <figure className="image-box">
                                    <img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/about-didi.jpg" alt="" />
                                </figure>
                            </div>
                        </div>



                    </div>


                    <div className="row">

                        <div className="col-lg-12 col-md-12 col-sm-12">

                            <div className="text">
                                Didi Ji is the founder of Radha Madhav Society, a non-profit charitable organization in America, Canada and Trinidad, functioning under the auspices of Jagadguru Kripalu Parishat. She is continuously striving to spread the deep spiritual teachings of Hinduism and dispel the myths and misconceptions related to it. An eloquent speaker, her mission is to simplify the eternal message of the Vedas and Shastras and awaken love for God within the hearts of seeking souls. Didi Ji's preaching is concentrated in the US, Canada, the West Indies, Singapore and Malaysia. She has inspired and transformed the lives of thousands.
                                With a mixture of eastern background and western upbringing, Didi Ji inspires adults, teens and children alike through her lectures, satsangs and devotional retreats. She is a vibrant speaker, a melodious singer, compassionate by nature and extremely approachable.


                                {/* <!--She continuously strives to spread the deep spiritual teachings of Hinduism and dispel the myths and misconceptions related to it. She is an eloquent speaker whose mission is to simplify the eternal message of the Vedas and Shastras and awaken love for God within the hearts of seeking souls. Didi Ji’s preaching is concentrated in the US, Canada and the West Indies, although she visits other parts of the world when time allows. She has inspired and transformed the lives of thousands of seekers around the globe.--> */}
                                <br />
                                {/* <!--<br>
                            With a mixture of eastern background and western upbringing, Didi Ji inspires adults, teens and children alike through her lectures, chanting, camps, workshops and Camps in various parts of the U.S. and the world.--> */}



                            </div>

                            <div className="share-post clearfix">
                                <div className="share-title">Connect Us</div>
                                <ul className="social-links clearfix">
                                    <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                    <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                                    <li><a href="#"><span className="fab fa-instagram"></span></a></li>
                                    <li><a href="#"><span className="fab fa-youtube"></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </section>



            <section className="call-to-action">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-pattern-1.png)' }}></div>

                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="title-column col-xl-9 col-lg-12 col-md-12 col-sm-12">
                            <h2>Become a vital part of Yugal Kunj &amp; make a real difference to lives of many!</h2>
                        </div>
                        <div className="link-column col-xl-3 col-lg-12 col-md-12 col-sm-12">
                            <div className="link-box">
                                <NavLink to='https://ykportal.yugalkunjtoronto.org/home/volunteeryk' className="theme-btn btn-style-one"><span className="btn-title">join to Volunteer</span></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Default Section--> */}
            <Footer />
        </>

    )

}


export default AboutDidiJi;


