const EventCardData =[

    {

        id:1,
        imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/event/recent-event1.jpg",
        Link:"#",
        title:"Life Story Of Shri Maharaji In His Own Words",
        Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },

    {

        id:2,
        imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/event/recent-event2.jpg",
        Link:"#",
        title:"Winter Retreat Blog",
        Desc:"Yugal Kunj celebrated its yearly winter family retreat with much anticipation and enthusiasm. Devotees from all over enjoyed the daily activities led by Sushri Siddheshvari Didiji. The week had three different schedules for teens, kids and adults focusing on different aspects for each age group...    "



    },

    {

        id:3,
        imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/event/recent-event3.jpg",
        Link:"#",
        title:"Karuna Sagar",
        Desc:"God is ‘Karuna Sagar.’ He is the unlimited ocean of mercy and compassion. Saint Tulsidas writes in Vinay Patrika that Bhagvan Shri Ram is compassionate to one and all. Gaurang Mahaprabhu says – O Krishna! There are only three ways in which You will behave towards me. You will give me a loving em........   "



    },




];


export default EventCardData;