import Footer from "./Component/Footer";
// import Header from "./Component/Header";

import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';

import OurEventCards from './Component/OurEventCards';
import OverEventTitleCards from './Component/OverEventTitleCards';
//import EventSchedules from './Component/EventSchedules';
import OurEventData from './Component/OurEventData';
import MenuBar from "./Component/MenuBar";



const OurEvent = () => {

    return (

        <>


            <MenuBar />
            {/* breadcrumb start here */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>
                <div className="upper-icon1"></div>
                <div className="auto-container">
                    <h1>Upcoming Events</h1>
                </div>
                <div className="upper-icon1"></div>
                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/CAD/Home">Home</Link></li>
                            <li className="active">Events</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/*  breadcrum end here */}

            <Container>
                <h4 className="text-center" style={{ padding: '50px' }}>Event Coming Soon </h4>

                {/* {OurEventData.map((val, index) => {
                    return (

                        <OverEventTitleCards
                            key={val.id}
                            title={val.title}
                            links={val.links}
                            lablename={val.lablename}

                            imgscr={val.imgsrc}
                            imglinks={val.imglinks}

                        />


                    )

                })} */}





            </Container>




            <Footer />
        </>


    )




}

export default OurEvent;