const LeaveAMessage = () => {
return(
   <> 
       <section className="contact-section">
            {/* <!-- <div className="icon-one paroller" data-paroller-factor="-0.20" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-heart-2"></span></div> -->
            <!--  <div className="icon-two paroller" data-paroller-factor="0.20" data-paroller-factor-lg="0.15" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-heart-2"></span></div> --> */}

            <div className="icon-three paroller" data-paroller-factor="-0.10" data-paroller-factor-lg="-0.15" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-dove"></span></div>

            <div className="auto-container">

                <div className="sec-title centered">
                    <div className="upper-icon"></div>
                    <h2>Leave a Message</h2>
                    {/* <!-- <div className="text">Join Us To Save The World Of Humanitarian And Feel Happy To Support</div> --> */}
                </div>
                <div className="row clearfix">

                    {/* <!--Form Column---> */}
                    <div className="form-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                        <div className="inner">

                            <div className="default-form contact-form">


                                <div className="col-md-12 col-sm-12">
                                    <div className="inner-button">


                                        <a href="https://ykportal.yugalkunj.org/home/contact" target="_blank" className="theme-btn btn-style-one" style={{width:"300px", textAlign: "center", color:"#fff" }}><span className="btn-title"> Please Click Here</span></a>

                                    </div>
                                </div>


                                
                            </div>
                        </div>
                    </div>
                    {/* <!--Image Column---> */}
                    <div className="image-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                        <div className="inner" style={{
                             marginLeft: '-38px',
                             marginRight: '-4px'
                        }
                           
                        }>
                            {/* <div className="icon-1 paroller" data-paroller-factor="-0.30" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.20" data-paroller-type="foreground" data-paroller-direction="vertical"></div>
                            <div className="icon-2 paroller" data-paroller-factor="0.30" data-paroller-factor-lg="0.20" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="vertical"></div> */}
                            <figure className="image">
                                <img src="https://yugalkunjtoronto.org/images/resource/contact.png" alt=""/>
                            </figure>
                        </div>
                    </div>

                </div>
            </div>


        </section>
   </>

)


}


export default LeaveAMessage; 