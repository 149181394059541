const ServiceData = [

    {
        id: 1,
        imgsrc: "https://yugalkunjtoronto.org/images/resource/inspirational-1.jpg",
        title: "Temple/Spiritual Activities:",
        Desc: "Daily prayers and aarti, weekly Sunday satsang and celebration of major festivals",
        Link: "https://www.radhamadhavsociety.org/",
        Readbtn: "Read More",
        registerbtn: "Register"

    },

    {
        id: 2,
        imgsrc: "https://yugalkunjtoronto.org/images/main-slider/torento1.jpg",
        title: "Yugalkunj Visit",
        Desc: "Click here if you are visiting yugalkunj ",
        Link: "#",
        Readbtn: "Read More",
        registerbtn: "Register"

    }



    // { 
    //     id: 3,
    //     imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/featured-image-6.jpg",
    //     title:"Yugalkunj Visit",                    
    //     Desc:"Click here to register for yugalkunj visit registration.",
    //     Link:"https://ykportal.yugalkunj.org",
    //     Readbtn :"Read More",
    //     registerbtn :"Register"

    // },



    // { 
    //     id: 4,
    //     imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/Geeta-Beginner.png",
    //     title:"Classes",                    
    //     Desc:"Radha Madhav Society offers Gurukul classes to open young minds to spirituality and the richness of Hinduism in a very ...",
    //     Link:"https://ykportal.yugalkunj.org/",
    //     Readbtn :"Read More",
    //     registerbtn :"Register"

    // },



    // { 
    //     id: 5,
    //     imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/inspirational-1.jpg",
    //     title:"Temple/Spiritual Activities",                    
    //     Desc:"Spiritual nuggets from the Words of Wisdom that are written by Didi Ji and sent out every Friday.",
    //     Link:"https://ykportal.yugalkunj.org/",
    //     Readbtn :"Read More",
    //     registerbtn :"Register"

    // },


    // { 
    //     id: 6,
    //     imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/cause-image-3.jpg",
    //     title:"Prasad/Tiffin",                    
    //     Desc:"Prasad is sacred vegetarian food shared with devotees after it has been lovingly prepared and offered to God...",
    //     Link:"https://ykportal.yugalkunj.org/Tiffin/Home",
    //     Readbtn :"Read More",
    //     registerbtn :"Register"

    // },


    // { 
    //     id: 7,
    //     imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/about-2.jpg",
    //     title:"Flowery Grove Montessori School",                    
    //     Desc:"We provide authentic Montessori education in a natural and safe environment. The serene campus, beautifully prepared classroom",
    //     Link:"https://flowerygrovemontessori.org/",
    //     Readbtn :"Read More",
    //     registerbtn :"Register"

    // },


    // { 
    //     id: 8,
    //     imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/HomeworkHelp.png",
    //     title:"Harmony Youth Center-Homework Help",                    
    //     Desc:"Harmony youth center (HYC) is a non-profit incorporation dedicated to serving the communit.",
    //     Link:"https://harmonyyouthcenter.org/",
    //     Readbtn :"Read More",
    //     registerbtn :"Register"

    // }







];

export default ServiceData; 