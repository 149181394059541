const EventCardData = [

    {

        id: 1,
        title: "Humility-The Foundation of all virtues",
        links: "#",
        lablename: " Please click her for registration",
        imgsrc: "https://yugalkunj.org/images//Event/DiwakariDidiFlyer-Aug2023.jpg",
        imglinks: "https://yugalkunj.org/images//Event/DiwakariDidiFlyer-Aug2023.jpg"


        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },


    {

        id: 2,
        title: "Labor Day Seva and Sadhana Retreat",
        links: "https://ykportal.yugalkunj.org/",
        lablename: " Please click her for registration",
        imgsrc: "https://yugalkunj.org/images//Event/LaborDayRetreatFlyer.jpg",
        imglinks: "https://yugalkunj.org/images//Event/LaborDayRetreatFlyer.jpg"



    },

    {

        id: 3,
        title: "Grand Janmashtami Celebrations at yugal kunj",
        links: "https://ykportal.yugalkunj.org/",
        lablename: " Please click her for registration",
        imgsrc: "https://yugalkunj.org/images//Event/JanmashtamiCelebrations2023.jpg",
        imglinks: "https://yugalkunj.org/images//Event/JanmashtamiCelebrations2023.jpg"

        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },

    {

        id: 4,
        title: "In-Person Gurukul {Hinduism} Class",
        links: "https://ykportal.yugalkunj.org/",
        lablename: " Please click her for registration",
        imgsrc: "https://yugalkunj.org/images//Event/GurukulFlyers-ATL.jpg",
        imglinks: "https://yugalkunj.org/images//Event/GurukulFlyers-ATL.jpg"

        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },

    {

        id: 5,
        title: "Virtual Hindi Classes",
        links: "https://ykportal.yugalkunj.org/",
        lablename: " Please click her for registration",
        imgsrc: "https://yugalkunj.org/images//Event/HindiFlyers.jpg",
        imglinks: "https://yugalkunj.org/images//Event/HindiFlyers.jpg"

        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },

    {

        id: 5,
        title: "Prem Ras Siddhanth",
        links: "https://ykportal.yugalkunj.org/",
        lablename: " Please click her for registration",
        imgsrc: "https://yugalkunj.org/images//Event/PRS-Adult.jpg",
        imglinks: "https://yugalkunj.org/images//Event/PRS-Adult.jpg"

        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    }




]

export default EventCardData;