import Footer from "./Component/Footer";
// import Header from "./Component/Header";
import MenuBar from "./Component/MenuBar";
import { Link } from 'react-router-dom'

const TempleActivities = () => {
    return (
        <>
            {/* <Header/> */}

            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>Temple Activities</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>
                            <li className="active">Temple Activities</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}
            {/* <!--FAQs Section--> */}
            <section className="faq-section">
                <div className="auto-container">

                    <div className="row clearfix">

                        <div className="col-md-12">



                            <div className="panel panel-primary">
                                <div className="panel panel-heading">

                                    <h4>Temple Activities</h4>
                                </div>


                                <div className="panel panel-body">
                                    <div className="inner-heading">



                                        <div className="text">


                                            The temple have deities of Radha-Krishna and Sita-Ram and is a place of worship that promotes selfless devotion to God. Apart from daily prayers and Artis, the temple aims to have regular satsangs, periodic discourses by preachers of Jagadguru Kripalu Parishat, provide easy access to preacher and priest, literature and devotional media such as books, audio CDs and video DVDs to help individuals and families advance on the spiritual path.
                                            The temple will also celebrate major Hindu festivals such as Shivratri, Holi, Ram Naumi, Guru Poornima, Janmashtami, Radhashtami and Divali.


                                        </div>



                                        <div className="inner_heading_title orange-border" style={{ marginTop: '10px' }}>
                                            <h4> Weekly Satsangs </h4>

                                            <div className="text">

                                                <p className="lead">
                                                    Weekly satsangs are held every Sunday from 10am to 12 noon. The typical satsang follows the schedule shown below
                                                </p>

                                                <ul className="satsang_list">

                                                    <li>Prayer</li>
                                                    <li> Bhajans</li>
                                                    <li>Video discourse by Jagadguru Shri Kripalu Ji Maharaj</li>
                                                    <li>Parallel classes for children</li>
                                                    <li>Aarti</li>
                                                    <li>Lunch Prasad</li>




                                                </ul>
                                            </div>

                                        </div>


                                        <div className="inner_heading_title purple-border">
                                            <h4> Sunday School </h4>

                                            <div className="text">

                                                <p className="lead">The Following educational and cultural classes are offered:</p>

                                                <ul className="satsang_list">

                                                    <li>Hinduism</li>
                                                    <li>Dholak</li>
                                                    <li>Harmonium</li>
                                                    <li>Dance</li>
                                                    <li>Singing</li>
                                                    <li>Indian languages such as Hindi</li>
                                                    <li>Leadership Workshop</li>


                                                </ul>
                                            </div>

                                        </div>



                                    </div>



                                </div>



                            </div>

                        </div>


                    </div>


                </div>
            </section>


            <Footer />

        </>


    )


}

export default TempleActivities;