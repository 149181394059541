
const ServiceCards = (props) => {

  return (


    <>




      <div className="cause-block1 col-lg-4 col-md-6 col-sm-12 ">
        <div className="inner-box1 wow fadeInUp animated"  >
          <div className="image-box1">
            <figure className="image1">
              <a href={props.Link} >
                <img src={props.imgsrc} alt="" /></a>
            </figure>

          </div>
          <div className="lower-content1">
            <h3><a href="campinfo.html">  {props.title}</a></h3>

            <div className="text1">

              {props.Desc}
              <a href={props.Link} className="readmore"> {props.Readbtn}</a>



            </div>
            <a href={props.Link} target="_blank" className="theme-btn1 btn-style-two1">
              <span className="btn-title1">
                <span className="icon fa fa-sign-in-alt" aria-hidden="true">
                </span> {props.registerbtn} </span>
            </a>
          </div>



        </div>


      </div>

    </>


  )

}

export default ServiceCards;