// import GalleryCard from "./GalleryCard"
import GalleryCard from "./Component/GalleryCard";
import GalleryCardData from "./Component/GalleryCardData";
// import Header  from "./Component/Header";
import Footer from "./Component/Footer";
import MenuBar from "./Component/MenuBar";
import { NavLink } from "react-router-dom";
const OurGallery = () => {
    return (
        <>

            <MenuBar />
            {/* <Header/> */}
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>Our Gallery</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>

                            <li className="active"> Our Gallery</li>
                        </ul>
                    </div>
                </div>

            </section>



            <section className="activity-section">

                <div className="auto-container">
                    <div className="sec-title centered">
                        <div className="upper-icon"></div>
                        <h2>Our Gallery</h2>
                        {/* <!-- <div className="text">We believe what we achieve</div> --> */}
                    </div>

                    <div className="mixit-gallery">
                        {/* <!--Filter--> */}



                        <div className="filter-list row clearfix" id="MixItUp7B5F74">



                            {/* <GalleryCard 
              imgsrc="https://stssevastorage.blob.core.windows.net/retreat/r1.JPG"
              Link="https://stssevastorage.blob.core.windows.net/retreat/r1.JPG"
            /> */}

                            {GalleryCardData.map((val, index) => {
                                return (

                                    <GalleryCard
                                        key={val.id}

                                        imgsrc={val.imgsrc}
                                        Link={val.Link}


                                    />
                                );

                            })}



                        </div>


                        <div className="bottom-info">

                            <div className="link-box"><NavLink to='/USA/OurGallery' className="theme-btn btn-style-five"><span className="btn-title">View More Activities</span></NavLink></div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />

        </>

    )

}

export default OurGallery;